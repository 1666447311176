<i18n>
{
  "en": {
    "browser_title": "Upgrade",
    "upgrade": "Upgrade",
    "tab_download_plans": "Download Plans",
    "tab_streaming_plans": "Streaming Plans"
  },
  "ja": {
    "browser_title": "アップグレードのご案内",
    "upgrade": "アップグレードのご案内",
    "tab_download_plans": "ダウンロードプラン",
    "tab_streaming_plans": "ストリーミングプラン"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />

    <!-- only show upgrade if we are before the new experience -OR- after newxp and classic DL -->
    <div id="upgrade" v-if="!newXPEnabled || (newXPEnabled && !newXPEligible)">
      <div class="block-header">
        <div class="container">
          <h1 @click="getClientID()">{{ $t('upgrade') }}</h1>
          <!-- hidden UI interaction to expose GA Client ID without poking into debug (for sales) -->
          <div v-if="CID.isVisible">{{ CID.val }}</div>
        </div>
      </div>

      <!-- banner -->
      <section id="ug-banner">
        <div class="container">
          <upgrade-image />
        </div>
      </section>

      <section id="ug-upgrade" v-if="locale == 'ja'">
        <div class="container">
          <div class="section is-wide">
            <h2 class="is-secondary">アップグレード</h2>
            <p>アップグレード料金は、各ご希望プランの料金とお客様が現在ご利用のプランの基本料金との差額となります。各プランへのアップグレード料金は下記アップグレードプラン表の「アップグレード」ボタンよりご確認ください。</p>

            <!-- plan tables -->
            <div class="section">
              <package-table currency="USD" package-type="upgrade" />
            </div>

            <div class="ug-note is-note">
              <ul class="has-style is-disc">
                <li>退会処理後であっても会員有効期間中はアップグレードが可能ですが、会員期間終了後のアップグレードはできません。</li>
                <li>退会処理後にアップグレードをすると退会手続きが無効となり、再度自動継続会員として登録されますのでご注意ください。</li>
                <li>アップグレード後の会員有効期限は、アップグレードした日からではなく、最後に課金が発生した日 (入会日または継続日) からの計算となりますのでご注意下さい。</li>
                <li>割引期間中にはアップグレードに対して割引クーポンはご利用いただけません。</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="ug-about" v-if="locale == 'ja'">
        <div class="container">
          <div class="section is-wide">
            <h2 class="is-secondary">VIP / 超VIP / 年間会員とは</h2>
            <p>VIP会員、超VIP会員、年間会員とは、一般会員と比べ月々の会員費 (月換算での会員費) が大幅に安くなる上に、会員限定作品やダウンロード上限UPなど各種特典が付いてくる、ワンランク上の会員ステータスです。</p>
            <p>パコパコママでは、お客様が現在お持ちのプラン料金とご希望のプラン料金との差額をお支払いいただくことにより、いつでも上位の会員ステータスへアップグレードすることができます。</p>
            <div class="ug-feature">
              <div class="feature-item">
                <div class="feature-figure">
                  <div class="feature-numbering">01</div>
                  <img class="feature-image" src="/img/pages/upgrade/ug-feature-fee.svg" alt="">
                </div>
                <div class="feature-heading">格安料金</div>
                <div class="feature-text">会員ランクが上がれば上がるほど、月単価は断然お得になります。最大でなんと約$12/月の割引価格が適用されます (会員費を月換算で比較した場合)。</div>
              </div>
              <div class="feature-item">
                <div class="feature-figure">
                  <div class="feature-numbering">02</div>
                  <img class="feature-image" src="/img/pages/upgrade/ug-feature-dml.svg" alt="">
                </div>
                <div class="feature-heading">ダウンロード上限UP</div>
                <div class="feature-text">VIP会員になると1日のダウンロード上限が4本、また超VIP会員/年間会員になると5本へとそれぞれアップします (一般会員の上限は3本)。ダウンロード上限が増えることで、1日により多くの作品をお楽しみいただけます。</div>
              </div>
              <div class="feature-item">
                <div class="feature-figure">
                  <div class="feature-numbering">03</div>
                  <img class="feature-image" src="/img/pages/upgrade/ug-feature-ppv.svg" alt="">
                </div>
                <div class="feature-heading">単品割引</div>
                <div class="feature-text">パコパコママを継続中のお客様には単品購入サイト「カリビアンコムプレミアム」の動画を最大10%OFFの特別会員割引価格でご購入いただけます。(※先行予約販売やキャンペーン割引中の商品は対象外)</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="ug-qualification" v-if="locale == 'ja'">
        <div class="container">
          <div class="section is-wide">
            <h2 class="is-secondary">VIP / 超VIP / 年間会員になるには？</h2>
            <p>各会員ステータスになるには、大きく分けて「新規入会」「アップグレード」「継続による自動アップグレード」の3通りの方法があります。<br><span class="is-note">※年間会員は現在アップグレードのみでのご提供となります。</span></p>
            <h3>VIP会員になるには</h3>
            <ul class="has-style is-disc">
              <li>VIP会員として新規入会</li>
              <li>一般会員として入会し、VIP会員へアップグレード</li>
              <li>一般会員として入会後、1回目の継続から自動アップグレード</li>
            </ul>
            <h3>超VIP会員になるには</h3>
            <ul class="has-style is-disc">
              <li>超VIP会員として新規入会</li>
              <li>一般会員またはVIP会員として入会し、超VIP会員または年間会員へアップグレード</li>
              <li>一般会員として入会後、2回目の継続から自動アップグレード</li>
              <li>VIP会員として入会後、1回目の継続から自動アップグレード</li>
            </ul>
            <h3>年間会員になるには</h3>
            <ul class="has-style is-disc">
              <li>一般会員、VIP会員または超VIP会員として入会し、年間会員へアップグレード</li>
              <li>一般会員、VIP会員または超VIP会員として入会後、361日目から自動アップグレード</li>
            </ul>
          </div>
        </div>
      </section>
      <!-- /japanese text -->

      <!-- english text -->
      <section id="ug-upgrade" v-if="locale == 'en'">
        <div class="container">
          <div class="section is-wide">
            <h2 class="is-secondary">Upgrade Plans & Fees</h2>
            <p>Upgrade fee will be a difference between the fee of each desired plan and the basic fee of your current plan. Please check the upgrade fee for each plan from "Upgrade" button in the price table below.</p>

            <!-- plan tables -->
            <div class="section">
              <package-table currency="USD" package-type="upgrade" />
            </div>

            <div class="ug-note is-note">
              <ul class="has-style is-disc">
                <li>Even if you cancel your membership, upgrade is still available during your membership validity period.</li>
                <li>Please note that if you upgrade after your membership cancellation, your cancellation process will be invalid and you will be registered again as an automatic recurring member.</li>
                <li>Please note that the membership period after the upgrade will be set from the date of the last payment (sign up or recurring), not from the date of upgrade.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="ug-qualification" v-if="locale == 'en'">
        <div class="container">
          <div class="section is-wide">
            <h2 class="is-secondary">How to become a VIP / Super VIP / Annual member?</h2>
            <p>There are three ways to become each member status, which are "Sign up", "Upgrade" and "Automatic Upgrade by Recurring". (note: Annual membership is currently offered by upgrade only.)</p>

            <h3>VIP Member</h3>
            <ul class="has-style is-disc">
              <li>Sign up as VIP member</li>
              <li>Sign up as Regular member, then upgrade to VIP member</li>
              <li>Sign up as Regular member, then automatically upgraded from 1st recurring</li>
            </ul>
            <h3>Super VIP Member</h3>
            <ul class="has-style is-disc">
              <li>Sign up as Super VIP member</li>
              <li>Sign up as Regular or VIP member, then upgrade to Super VIP member</li>
              <li>Sign up as Regular member, then automatically upgraded from 2nd recurring</li>
              <li>Sign up as VIP member, then automatically upgraded from 1st recurring</li>
            </ul>
            <h3>Annual Member</h3>
            <ul class="has-style is-disc">
              <li>Sign up as Regular or VIP or Super VIP member, then upgrade to Annual member</li>
              <li>Sign up as Regular or VIP or Super VIP member, then automatically upgraded after 361 days</li>
            </ul>
          </div>
        </div>
      </section>
      <!-- english text -->
    </div><!-- /#upgrade -->
  </main>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import UpgradeImage from '@/components/upgrade/upgradeImage.vue';
import PackageTable from '@/components/upgrade/packageTable.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'upgrade-image': UpgradeImage,
    'package-table': PackageTable,
  },
  data() {
    return {
      dynamicTitle: '',
      CID: {
        val: null,
        isVisible: false,
        clickCount: 0,
      },
    };
  },
  created() {
    // non-reactive consts
    this.appHeaderHeight = APP_CONFIG.site.headerHeight;

    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  methods: {
    getClientID() {
      if (this.CID.clickCount === 0) {
        this.$analytics.getClientId().then((clientId) => {
          this.CID.val = clientId;
        });
      }
      if (this.CID.clickCount === 8) this.CID.isVisible = true;
      this.CID.clickCount += 1;
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEnabled() {
      return (process.env.VUE_APP_NEW_XP_ENABLED === 'true');
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_upgrade.scss';
</style>
